<script>
export default {
  props: {
    tutorial: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="study__preview">
    <div class="study__preview__title">
      {{ tutorial.title }}
    </div>

    <div class="study__preview__description" v-html="tutorial.description" />

    <router-link
      :to="{
        name: 'CoursePage',
        params: { id: tutorial.id, title: tutorial.title },
      }"
      class="study__preview__link"
    >
      <span class="">Смотреть курс</span>

      <span class="icon-arrow-medium" />
    </router-link>

    <div v-if="tutorial.thumbnail" class="study__preview__image">
      <img :src="tutorial.thumbnail.url" alt="" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.study__preview {
  width: 100%;
  padding: $space-xl $space-m;
  background: $light-primary;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;

  &__title {
    color: $dark-primary;
    margin: 0 0 $space-s;
    @include title-3;
    max-width: 246px;
  }

  &__description {
    max-width: 200px;
    color: $dark-primary;
    opacity: 0.8;
    @include body-1;
    margin-bottom: $space-m;

    :deep p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__link {
    margin-top: auto;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: $dark-primary;
    @include body-1-bold;

    .icon-arrow-medium {
      margin: 0 0 0 $space-s;
      background: $dark-ultra;
      height: 12px;
      min-width: 12px;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 150px;
    max-height: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      max-width: 100%;
      max-height: 235px;
    }

    @media (max-width: 475px) {
      width: 120px;
    }

    @media (max-width: 386px) {
      width: 100px;
    }
  }
}
</style>
